@font-face {
  font-family: 'HurmeGeometricSans1', sans-serif !important;
  src: url('/css/font/HurmeGeometricSans1-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.btn-secondary {
    border-radius: 100px !important;
    background: #a7806c;
    color: #FFF;
    padding: 6px 80px;
  }
  
  
  p {
    margin: 0 0 10px;
    font-size: 15px;
    font-family: 'HurmeGeometricSans1', sans-serif !important;
    color: #000;
    /* line-height: 22px; */
  }
  
  /* p strong {
    font-size: 25px;
  } */
  
  .heading-story {
    text-align: center;
    margin-top: 20px;
    font-size: 2.5rem;
    font-family: 'HurmeGeometricSans1', sans-serif !important;
  }
  
  .our-story-section {
    position: relative;
    text-align: center;
    color: white;
  }
  
  
  /* .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
   */
  .our-process-section {
    position: relative;
    text-align: center;
    color: white;
    background: url('images/ourfarm.png') no-repeat center center;
    background-size: cover;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ourfarm-content{
    font-size: 14px;
    text-align: justify;
  }
  @media (max-width: 1199.98px) {
    .heading-story {
      font-size: 2rem;
    }
  
    .btn-secondary {
      padding: 6px 60px;
    }
  }
  
  @media (max-width: 991.98px) {
    .col-lg-7,
    .col-lg-5 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  
    .btn-secondary {
      padding: 6px 40px;
    }
  
    .heading-story {
      font-size: 1.75rem;
    }
  
    .col-lg-5 img,
    .col-lg-7 img {
      /* width: 100%; */
      height: auto;
    }
  
    .d-flex img {
      width: 80%;
    }
  }
  
  @media (max-width: 767.98px) {
    .heading-story {
      font-size: 1.5rem;
    }
  
    .btn-secondary {
      padding: 6px 30px;
    }
  
    .d-flex img {
      width:50%;
    }
  
    .cow-milk {
      display: none;
    }
  
    .line-hide  .img-fluid{
      display: none;
    }
  
  
    .text-middle {
      text-align: center;
      display: block;
      width: 100%;
    }
  
    .divder-img {
      display: block;
      margin: 0 auto;
    }
    .cow-img {
      display: block;
      margin: 31px -69px;
  
    }
    .farm-img-div{
      display:flex;
      justify-content: center;
    }
    
  }
  .ourfarm-order-btn{
    margin: 0px !important;
    padding: 7px 20px;
    height: 52px !important;
    font-size: 16px !important;
    text-transform: uppercase;
    font-weight: 800;
    /* box-shadow: 7px 8px 11px #aba9a9; */
    border-radius: 50px;
  }
  