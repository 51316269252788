.product-detail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 5rem;
}

.product-detail-card {
    display: flex;
    border: 1px solid #d2ab67;
    border-radius: 10px;
    padding: 20px;
    width: 90%;
}

.product-detail-left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-detail-left img {
    max-width: 100%;
    border-radius: 10px;
}

.product-detail-right {
    flex: 2;
    padding-left: 20px;
}

.product-detail-right h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.product-package {
    font-size: 16px;
    color: gray;
}

.product-price {
    font-size: 20px;
    color: #d2ab67;
    margin: 10px 0;
}

.product-description {
    border: 1px dotted #d2ab67;
    padding: 10px;
    margin-bottom: 10px;
}

.order-info {
    margin-bottom: 10px;
    margin-top: 10px;
}

.frequency-buttons {
    margin-bottom: 10px;
    font-size: medium;
}

.frequency-button {
    background-color: white;
    border: 1px solid #d2ab67;
    color: #d2ab67;
    border-radius: 20px;
    padding: 5px 15px;
    margin-right: 5px;
    cursor: pointer;
}

.frequency-button:hover {
    background-color: #d2ab67;
    color: white;
}

.frequency-button.active {
    background-color: #d2ab67;
    color: white;
    margin:8px;
}

.quantity-selector {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.quantity-selector button {
    /* background-color: #d2ab67; */
    background-color: black;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.quantity-selector span {
    margin: 0 10px;
    font-size: 18px;
}

.date-picker-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.date-picker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.subscribe-button {
    background-color: #d2ab67;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
}

.subscribe-button:hover {
    background-color: #d18b45;
}

p {
    font-size: 18px;
    font-weight: 500;
    color: black;
}
button .btn .recharge-btn{
    background-color: #d2ab67 !important;
    color: white !important;
    border: none;
    border-radius: 20px !important;
    padding: 10px 20px !important;
}
.dialog-overlay{
    overflow-y: scroll;
}
@media only screen and (max-width: 600px){
    .product-detail-card {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        border: 1px solid #d2ab67;
        border-radius: 10px;
        padding: 20px;
        width: 90%;
    }
    .product-detail-right {
        flex: 2 1;
        padding-left: 2px;
    }
    .product-detail-left {
        flex: 1 1;
        display: flex;
        justify-content: left;
        align-items: center;
    }
}


